import React, { useState } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import { Container } from "../components/Container/Container";
import { Column } from "../components/Column/Column";
import { PostFold } from "../components/PostFold/PostFold";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import remarkGfm from "remark-gfm";
import CopyToClipboard from "react-copy-to-clipboard";
import CtaSection from "../components/CtaSection/CtaSection";
import { getFullImageUrl } from "../utils/strapi";
import { Copy } from "react-feather";

const ArticleTemplate = ({ data }) => {
  const fullImageUrl = getFullImageUrl(data.strapiBlogPost.postImage.url);

  return (
    <Layout
      pageTitle={data.strapiBlogPost.title}
      description={data.strapiBlogPost.description}
      image={fullImageUrl}
      jobPost
      isBlogPost
    >
      <PostFold
        title={data.strapiBlogPost.title}
        datePublished={data.strapiBlogPost.date}
        image={data.strapiBlogPost.postImage.localFile.childImageSharp.gatsbyImageData}
        backLinkUrl="/blog"
        backLinkTitle="Blog"
        wrapHeading
        author={data.strapiBlogPost.author.fullname}
        authorImg={data.strapiBlogPost.author.picture.formats.small.url}
      />

      <Container>
        <Column columns_mobile="12" columns_tablet="12" columns_desktop="12">
          <div className="blog-post">
            <div className="blog-post__description">
              <p>{data.strapiBlogPost.description}</p>
            </div>
            <div className="blog-post__main-holder">
              <ReactMarkdown
                children={data.strapiBlogPost.content.data.content}
                remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                      <CodeBlock
                        key={children}
                        code={String(children).replace(/\n$/, "")}
                        language={match[1]}
                        {...props}
                      />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  },
                }}
              />
            </div>
          </div>
        </Column>
      </Container>
      <CtaSection
        title="Join us"
        label="Look at our open positions"
        link="/careers/#job-listing"
      />
    </Layout>
  );
};

const CodeBlock = ({ code, language, ...props }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <CopyToClipboard text={code} onCopy={handleCopyClick}>
      <div className="code-snippet">
        <SyntaxHighlighter
          children={code}
          style={oneDark}
          language={language}
          PreTag="div"
          showLineNumbers
          showInlineLineNumbers
          {...props}
        />
        <button className={`btn btn--clipboard ${copied ? "copied" : ""}`}>
          {copied ? "Copied" : <Copy size="24" />}
        </button>
      </div>
    </CopyToClipboard>
  );
};

export default ArticleTemplate;

export const query = graphql`
  query ArticleTemplate($id: String!) {
    strapiBlogPost(id: { eq: $id }) {
      title
      description
      content {
        data {
          content
        }
      }
      date(formatString: "MMMM DD, YYYY")
      postImage {
        url
        name
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      author {
        fullname
        picture {
          url
          formats {
            small {
              url
            }
          }
        }
      }
    }
  }
`;
